.Leaderboard {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    gap: 32px;
    background: linear-gradient(180deg, #F0EBE2 0%, #DEDBD4 100%);

    .header {
        gap: 16px;
        padding: 36px;
        align-items: center;
        justify-content: space-evenly;

        .logo {
            max-width: 400px;
            align-self: center;
        }

        .text {
            gap: 16px;
            align-self: center;
            align-items: center;
            justify-content: center;
            text-align: center;

            .title.MuiTypography-root {
                font-size: 56px;
            }

        }
    }

    .loading {
        gap: 32px;
        padding-left: 36px;
    }

    .content {
        gap: 32px;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        padding-bottom: 36px;

        .MuiTypography-h2 {
            font-size: 36px;
        }

        .leaderboard {
            min-width: 40%;
            flex-basis: 60%;
            gap: 32px;

            .banner-wrapper {
                align-items: center;
                filter: drop-shadow(5px 5px 5px #00000075);
                height: 100px;

                .banner {
                    flex-grow: 1;
                    padding: 0 16px 0 32px;
                    gap: 32px;
                    background: #164E7E;
                    color: white;
                    align-items: center;
                    height: 100%;

                    .position {
                        align-self: center;

                        &.MuiTypography-h1 {
                            font-size: 48px;
                        }
                    }
                }

                svg {
                    height: 100%;
                    .triangle {
                        fill: #164E7E;
                    }
                }



                &.odd {
                    .banner { background: #269BB0; }
                    .triangle { fill: #269BB0; }
                }

            }

            .no-results {
                gap: 32px;
                padding-left: 36px;
                text-align: center;
            }
        }

        .overview {
            padding-right: 36px;
            gap: 32px;
            flex-basis: 40%;
            align-items: center;
            justify-content: center;

            .MuiTypography-root {
                flex-grow: 1;
                padding: 16px;
                background: rgba(255, 255, 255, 0.5);
                border-radius: 16px;
                text-align: center;

            }
        }

    }
}

@media (min-width: 1400px) {
    .Leaderboard {
        .header .text .title.MuiTypography-root {
            font-size: 80px;
        }

        .loading {
            gap: 64px;
        }

        .content {
            gap: 64px;

            .MuiTypography-h2 {
                font-size: 48px;
            }

            .leaderboard {
                min-width: 50%;

                .banner-wrapper {
                    height: 120px;

                    .banner .position.MuiTypography-h1 {
                        font-size: 64px;
                    }

                }
            }

            .overview {
                flex-basis: 40%;
            }
        }
    }
}

@media (min-width: 1800px) {
    .Leaderboard {
        .header .text .title.MuiTypography-root {
            font-size: 96px;
        }

        .content {

            .MuiTypography-h2 {
                font-size: 64px;
            }

            .leaderboard {

                .banner-wrapper {
                    height: 140px;

                    .banner .position.MuiTypography-h1 {
                        font-size: 72px;
                    }

                }
            }
        }
    }
}