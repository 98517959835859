.CalculatorResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .MuiTypography-root {
        text-align: center;

        &.brand-gradient {
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            background-image: linear-gradient(260deg, rgb(2, 0, 36), rgb(0, 212, 255));
            background-origin: padding-box;
        }

        &.MuiTypography-h1 {
            font-size: 64px;
        }
    }
}