@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: 'Lexend', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fugaz {
  font-family: 'Fugaz One', cursive!important;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.brand-highlight {
  color: #207F9E;
}

