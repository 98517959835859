.CalculatorForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    gap: 16px;

    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        .title {
            text-align: center;
        }


        .subtitle {
            text-align: center;
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            gap: 16px;
        }

        .estimate {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
        }

        .MuiFormControl-root.MuiTextField-root {
            width: 100%;
            max-width: 300px;
        }

        .MuiTypography-root.error {
            color: #d32f2f;
        }
    }

}

@media (min-width: 1200px) {
    .CalculatorForm{
        .footer {
            width: 75%;
        }
    }
}

@media (min-width: 1900px) {
    .CalculatorForm{
        .footer {
            width: 50%;
        }
    }
}