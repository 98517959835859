.Calculator {
    flex-grow: 1;
    display: flex;
    padding: 32px;
    align-content: center;
    justify-content: center;
    background: linear-gradient(180deg, #F0EBE2 0%, #DEDBD4 100%);

    .logo {
        max-width: 200px;
    }

}